<template>
    <recess-modal modal-size="small"
        variant="variant2"
        :show-modal="isModalVisible"
        @close="close"
    >
        <template slot="modal-title">
            <h3 class="u-fw-semi-bold">{{ PROVIDER.Portfolio.PortfolioOverview.Popup.ReactivateCourse.Title }}</h3> 
        </template>
        <template slot="modal-body">
            <p>{{ PROVIDER.Portfolio.PortfolioOverview.Popup.ReactivateCourse.Description.PartOne }}</p>
        </template>
        <template slot="modal-footer">
            <div class="text-right">
                <recess-button
                    variant="secondary"
                    :title="BUTTON_TEXT.reactivate"
                    class="mr-3 qa-modal-reactivate-course-button"
                    @click.native.prevent="reactivateCourse()"
                />
                <recess-button
                    variant="tertiary"
                    :title="BUTTON_TEXT.cancel"
                    class="qa-modal-reactivate-course-cancel-button"
                    @click.native.prevent="close()"
                />
            </div>
        </template>
    </recess-modal>
</template>

<script>
import { BUTTON_TEXT, API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { postItem } from '@/../../shared/api/SharedClient'
import { defaultErrorMessage } from '../../../../shared/constants/validationMessageHelper'

export default {
    name: 'ReactivateCoursePopupComponent',
    props: {
        isModalVisible: {
            type: Boolean,
            default: false
        }, 
        courseId: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            BUTTON_TEXT,
            PROVIDER,
            isDeleted: false,
            course: null
        }
    },  
    methods: {
        async reactivateCourse() { 
            
            let toastNotification = {
                    type: null,
                    message: null
                } 
            
            try {
                await postItem(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    `${API_CALL_URL_PATHS.courses}/${this.courseId}/reactivate`,
                    false
                )  

                toastNotification = {
                    type: 'success',
                    message: PROVIDER.Portfolio.PortfolioOverview.Popup.ReactivateCourse.SucessMessage
                }                  
 
                this.close() 
                let timeoutValue = 3000
				if(process.env.NODE_ENV !== 'production'){
					timeoutValue = 10000
				};
                setTimeout(() => {
                    this.$bus.emit('refreshCourseList')
                }, timeoutValue)

            } catch (error) { 
                toastNotification = {
                    type: 'error',
                    message: defaultErrorMessage
                } 
            }

            this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                root: true
            })   
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>
